/* eslint-disable jsx-a11y/anchor-is-valid, react/jsx-props-no-spreading */
import { useUser } from '@folklore/auth';
import { useRoutes, useUrlGenerator } from '@folklore/routes';
import { ArrowIcon } from '@micromag/core/components';
import { DataProvider } from '@micromag/data';
import '@micromag/intl/locale/fr';
import Viewer from '@micromag/viewer';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation, useRoute } from 'wouter';

// import { usePopularDocuments } from '../../hooks/useDocuments';
import * as AppPropTypes from '../../lib/PropTypes';

// import { useApi } from '../../contexts/ApiContext';
import { usePromotions } from '../../contexts/PromotionsContext';
import { useSite } from '../../contexts/SiteContext';
import DocumentsList from '../lists/DocumentsList';
import DocumentTitle from '../typography/DocumentTitle';
import SectionTitle from '../typography/SectionTitle';

import styles from '../../styles/documents/micromag-document.module.css';

/* prettier-ignore */
import '../../styles/micromag.css';

// const Viewer = null;

const propTypes = {
    id: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string,
    micromag: PropTypes.shape({
        components: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
            }),
        ),
    }).isRequired,
    metadata: PropTypes.shape({
        // eslint-disable-next-line react/forbid-prop-types
        subscriptionPromotion: PropTypes.object,
    }),
    image: AppPropTypes.image,
    paused: PropTypes.bool,
    current: PropTypes.bool,
    entered: PropTypes.bool,
    onEnter: PropTypes.func,
    onLeave: PropTypes.func,
    onComplete: PropTypes.func,
    onProgress: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    id: null,
    title: null,
    slug: null,
    image: null,
    metadata: null,
    paused: false,
    current: false,
    entered: false,
    onEnter: null,
    onLeave: null,
    onComplete: null,
    onProgress: null,
    className: null,
};

function MicromagDocument({
    id,
    title,
    slug,
    image,
    micromag,
    metadata,
    paused,
    entered,
    current,
    onEnter,
    onLeave,
    onComplete,
    onProgress,
    className,
}) {
    const url = useUrlGenerator();
    const { handle: siteHandle } = useSite();
    const { organisation = null, components = [] } = micromag || {};
    const {
        subscriptionPromotion = null,
        collections = [],
        micromagNumber = null,
    } = metadata || {};
    const isMicromagUrbania =
        (collections || []).findIndex(
            ({ slug: collectionSlug = null } = {}) =>
                collectionSlug === 'micromag-urbania' || collectionSlug === 'micromag-france',
        ) !== -1;

    const { branding = null } = organisation || {};
    const routes = useRoutes();
    const [, params] = useRoute(routes.micromag);
    const user = useUser();
    // const api = useApi();
    const { path: screenParam = null } = params || {};
    const routeScreenIndex = parseInt(screenParam || '1', 10) - 1;
    const [screenIndex, setScreenIndex] = useState(routeScreenIndex);
    const { id: currentScreenId = null } = components[screenIndex] || {};
    const basePath =
        slug !== null
            ? url('micromag', {
                  slug,
              })
            : null;
    const [, setLocation] = useLocation();
    const [screenViewed, setScreenViewed] = useState([]);
    useEffect(() => {
        if (screenIndex !== routeScreenIndex) {
            setScreenIndex(routeScreenIndex);
        }
    }, [routeScreenIndex]);

    const ended = slug !== null && components !== null && screenIndex === components.length - 1;
    const backRoute =
        slug !== null && components !== null
            ? url('micromag', { slug, path: components.length - 1 })
            : null;

    const onScreenChange = useCallback(
        (screen) => {
            const newScreenIndex = components.findIndex(
                ({ id: screenId }) => screenId === screen.id,
            );

            let progress = null;
            if (screenViewed.indexOf(screen.id) === -1) {
                const newScreenViewed = [...screenViewed, screen.id];
                setScreenViewed(newScreenViewed);
                progress = newScreenViewed.length / components.length;
            }
            const lastIndex = components.length - 1;
            setLocation(
                url(
                    'micromag',
                    newScreenIndex > 0
                        ? {
                              slug,
                              path: newScreenIndex + 1,
                          }
                        : {
                              slug,
                          },
                ),
            );
            setScreenIndex(newScreenIndex);
            if (newScreenIndex > 0 && newScreenIndex < lastIndex && onEnter !== null) {
                onEnter();
            } else if (newScreenIndex === lastIndex && onLeave !== null) {
                onLeave();
            }

            if (progress !== null && onProgress !== null) {
                onProgress(progress);
            }

            if (progress === 1 && onComplete !== null) {
                onComplete();
            }
        },
        [
            components,
            setLocation,
            slug,
            entered,
            onEnter,
            onLeave,
            screenViewed,
            onComplete,
            onProgress,
        ],
    );

    const titleParts = title !== null ? title.split('–') : null;
    const [mainTitle = null, badgeTitle = null] = titleParts || [];

    const viewerMenuHeader = (
        <div className={styles.menuHeader}>
            {badgeTitle !== null ? <div className={styles.badge}>{badgeTitle}</div> : null}
            {mainTitle !== null ? (
                <DocumentTitle className={styles.title}>{mainTitle}</DocumentTitle>
            ) : null}
        </div>
    );

    const viewerMenuFooter = (
        <div className={styles.menuFooter}>
            <DocumentsList
                query={{
                    type: 'micromag',
                    collection: `micromag-${siteHandle}`,
                    exclude: id,
                }}
                cardType="over"
                cardRatio="micromag"
                cardTheme="outline"
                minColumns={3}
                header={
                    <SectionTitle className={styles.title}>
                        <FormattedMessage defaultMessage="Plus de micromags" />
                    </SectionTitle>
                }
            />
        </div>
    );

    // const onEnd = useCallback(() => {
    //     if (onLeave !== null) {
    //         onLeave();
    //     }
    // }, [onLeave]);

    // const onClose = useCallback(() => {
    //     if (onLeave !== null) {
    //         onLeave();
    //     }
    // }, [onLeave]);

    const { addPromotion, removePromotion } = usePromotions();
    useEffect(() => {
        if (id === null || !isMicromagUrbania) {
            return () => {};
        }
        const {
            screenIndex: subscriptionScreenIndex = null,
            blockLandingScreen = false,
            blockedScreens = null,
        } = subscriptionPromotion || {};

        const finalSubscriptionScreenIndex =
            subscriptionScreenIndex !== null ? subscriptionScreenIndex : 2;
        const shouldAllowCurrentScreen =
            routeScreenIndex >= finalSubscriptionScreenIndex && !blockLandingScreen;

        const promotionId =
            micromagNumber !== null
                ? `subscription_micromag_${micromagNumber}`
                : 'subscription_micromag';

        const promotion = {
            type: 'subscription',
            placement: 'modal',
            force: true,
            priority: 1,
            theme: 'full',
            title: 'Abonnez-vous<br/>pour continuer',
            description:
                siteHandle === 'france'
                    ? 'Vous recevrez nos micromags gratuitement et en exclusivité tous les 3 mois.'
                    : 'Vous recevrez nos micromags gratuitement et en exclusivité chaque samedi.',
            titleLogin: 'Connectez-vous<br/>pour continuer',
            descriptionLogin: 'Vous aurez accès à tous les micromags en exclusivité.',
            trigger: {
                event: 'one',
                delay: 5000,
                triggers: [
                    ...(blockedScreens || []).map((blockedScreenIndex) => ({
                        event: 'register',
                        pageMatch: {
                            type: 'micromag',
                        },
                        screenIndex: blockedScreenIndex,
                    })),
                    ...(blockedScreens || []).map((blockedScreenIndex) => ({
                        event: 'pageview',
                        pageMatch: {
                            type: 'micromag',
                        },
                        screenIndex: blockedScreenIndex,
                    })),
                    !shouldAllowCurrentScreen
                        ? {
                              event: 'register',
                              pageMatch: {
                                  type: 'micromag',
                              },
                              minScreenIndex: finalSubscriptionScreenIndex,
                          }
                        : null,
                    shouldAllowCurrentScreen
                        ? {
                              event: 'pageview',
                              pageMatch: {
                                  type: 'micromag',
                              },
                              maxScreenIndex: routeScreenIndex - 1,
                          }
                        : null,
                    {
                        event: 'pageview',
                        pageMatch: {
                            type: 'micromag',
                        },
                        minScreenIndex: shouldAllowCurrentScreen
                            ? routeScreenIndex + 1
                            : finalSubscriptionScreenIndex,
                    },
                ].filter((it) => it !== null),
            },
            ...subscriptionPromotion,
            subscription: siteHandle === 'france' ? 'micromag_france' : 'micromag',
            id: promotionId,
            handle: promotionId,
        };

        addPromotion(promotion);
        return () => {
            removePromotion(promotion.id);
        };
    }, [id, subscriptionPromotion, isMicromagUrbania, siteHandle, micromagNumber]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    'drag-x-disabled': entered,
                    [className]: className !== null,
                },
            ])}
        >
            <DataProvider apiBaseUrl="https://microm.ag/api">
                <div className={styles.content}>
                    {micromag !== null ? (
                        <Viewer
                            story={micromag}
                            theme={branding}
                            locale="fr"
                            visitor={user}
                            paused={paused}
                            basePath={basePath}
                            screen={currentScreenId}
                            closeable={false}
                            className={styles.micromag}
                            trackingDisabled={!current}
                            neighborScreensActive={entered ? 1 : 0}
                            withoutRouter
                            menuHeader={viewerMenuHeader}
                            menuFooter={viewerMenuFooter}
                            // withoutMenu
                            // withoutGestures={!entered}
                            withoutGestures
                            // withoutTransitions
                            // withoutMenu
                            // withoutScreensMenu
                            // withoutShareMenu
                            // googleApiKey={googleApiKey}
                            // onViewModeChange={onViewModeChange}
                            onScreenChange={onScreenChange}
                            // onMenuChange={onMenuChange}
                            // onInteraction={onInteraction}
                            // onClose={() => console.log('close')}
                            // onEnd={onEnd}
                            // withoutPlaybackControls={withoutPlaybackControls}
                            withNavigationHint="hand"
                            beforeScreensMenuButton={
                                ended ? (
                                    <Link
                                        className={styles.menuBack}
                                        href={backRoute}
                                        onClick={onEnter}
                                    >
                                        <ArrowIcon className={styles.menuBackIcon} />
                                        <span className={styles.menuBackLabel}>Retour</span>
                                    </Link>
                                ) : null
                            }
                        />
                    ) : null}
                </div>
            </DataProvider>
        </div>
    );
}

MicromagDocument.propTypes = propTypes;
MicromagDocument.defaultProps = defaultProps;

export default MicromagDocument;
